import React from 'react';
import { Link } from 'gatsby';

import './index.scss'

export const Top = ({ title }) => {
  return (
    <div className="top">
      <Link to={`/`} className="link">
        <span className="title">{(title ? title : `Neofundamentalist`)} | Home</span>
      </Link>
      <a className="item">
        <button 
          className="subscribe" 
          onClick={() => window?.open('http://eepurl.com/gY8ZAr')}
        >
          Subscribe
        </button>
      </a>
    </div>
  )
}

export default Top
